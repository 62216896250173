import { useEffect } from 'react';
import '../App.css';

function LinkTree() { 

    useEffect(() => {
        window.location.href="https://linktr.ee/d.natural.mx";
    }, []);
    
    return (<> 
        <div></div>
    </>);
}
export default LinkTree;